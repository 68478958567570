import React, { useEffect, useRef } from 'react';
import { motion, useAnimation } from 'framer-motion';

const AboutCard = () => {
  const cardRef = useRef(null);
  const controls = useAnimation();

  useEffect(() => {
    const cardElement = cardRef.current;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            controls.start({
              opacity: 1,
              rotateY: 0,
              transition: { duration: 1, ease: 'easeInOut' },
            });
          }
        });
      },
      { threshold: 0.5 }
    );

    if (cardElement) {
      observer.observe(cardElement);
    }

    return () => {
      if (cardElement) {
        observer.unobserve(cardElement);
      }
    };
  }, [controls]);

  return (
    <motion.div
      className="m-10 embla-card bg-gray-200 px-4 py-3 rounded-lg text-center shadow-lg"
      ref={cardRef}
      initial={{ opacity: 0, rotateY: 90 }}
      animate={controls}
    >
      <h2 className='text-3xl font-bold'>About Us</h2>
      <p className='sm:text-lg text-sm'>
        The Aims School isn't just an educational institution; it's a transformative journey. With a rich legacy spanning decades, The Aims School has excelled across various educational domains, offering a comprehensive network of educational services. From nurturing young minds in Schools, to providing comprehensive Evening Coaching, Entry Test Preparation, and Competitive Exam Preparation. The Aims School stands as a beacon of educational prowess. Complemented by a distinguished cadre of accomplished students and professionals, our institution takes pride in its commitment to excellence.
      </p>
    </motion.div>
  );
};

export default AboutCard;
