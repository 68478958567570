import React, { useEffect, useRef } from "react";
import useEmblaCarousel from "embla-carousel-react";
import { motion } from "framer-motion";
import image1 from "../media/teacher.jpeg";
import image2 from "../media/teacher12.jpeg";
import image3 from "../media/teacher3.jpg";
import image4 from "../media/gallery8.jpeg";
import image5 from "../media/teacher7.png";
import image6 from "../media/fTeacher1.png";
import image7 from "../media/fTeacher2.png";
import image8 from "../media/fTeacher3.png";
import image9 from "../media/fTeacher4.png";
import image10 from "../media/fTeacher5.png";

import './about.css';

const AnimatedCarousel = () => {
  const [viewportRef, embla] = useEmblaCarousel({ loop: true });
  const slideRef = useRef([]);

  useEffect(() => {
    if (embla) {
      embla.on("select", () => {
        const selectedIndex = embla.selectedScrollSnap();
        const slidesLength = embla.scrollSnapList().length;

        if (selectedIndex === slidesLength - 1) {
          setTimeout(() => {
            embla.scrollTo(0);
          }, 10000);
        }
      });

      const interval = setInterval(() => {
        if (embla) {
          if (!embla.canScrollNext()) {
            embla.scrollTo(0);
          } else {
            embla.scrollNext();
          }
        }
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [embla]);

  return (
    <div className="embla bg-gray-200 rounded-lg mx-10 py-5 embla-card shadow-lg">
      <div className="embla__viewport" ref={viewportRef}>
        <div className="embla__container">
          <motion.div
            className="embla__slide"
            ref={(el) => (slideRef.current[0] = el)}
          >
            <img
              className="w-1/2 rounded-lg mx-auto my-0 h-96 object-cover object-top img-fluid"
              src={image1}
              alt="img-1"
            />
            <p className="mx-10 my-5 text-center card-paragraph">
              The Aims School, under the leadership of Principal <span className="text-3xl card-title font-bold">Muhammad
              Tayyab</span>, stands as a beacon of educational distinction and
              commitment to nurturing young minds. Expanding its educational
              reach, The Aims School has established itself as a trusted name in
              the educational landscape, extending its services to cater to
              students in various cities. Encouraging students to explore their
              intellectual boundaries, uphold moral values, and strive for
              excellence in every endeavor, the institution remains dedicated to
              the holistic growth and development of its students.
            </p>
          </motion.div>
          <motion.div
            className="embla__slide"
            ref={(el) => (slideRef.current[1] = el)}
          >
            <img
              className="w-1/2 rounded-lg mx-auto my-0 h-96 object-cover object-top img-fluid"
              src={image2}
              alt="img-2"
            />
            <p className="mx-10 my-5 text-center card-paragraph">
            <span className="text-3xl card-title font-bold">Sir Salaar</span>, a dedicated professional in the field of Accounting &
              Finance from superior college. He brings a wealth of expertise and
              passion for teaching to the forefront. With 4 years of invaluable
              teaching experience, Sir Salaar exemplifies a commitment to
              shaping the future of aspiring accounting and finance
              professionals. His commitment to providing a
              stimulating learning environment and guiding students on a path
              toward professional success resonates with the institution's core
              values of academic rigor and personal growth.
            </p>
          </motion.div>
          <motion.div
            className="embla__slide"
            ref={(el) => (slideRef.current[1] = el)}
          >
            <img
              className="w-1/2 rounded-lg mx-auto my-0 h-96 object-top img-fluid"
              src={image3}
              alt="img-3"
            />
            <p className="mx-10 my-5 text-center card-paragraph">
            <span className="text-3xl card-title font-bold">Sir Umar Arif</span>, an esteemed graduate in Information Technology From
              NCBA, is a visionary educator committed to creating a unique and
              enriching learning journey for children. With a strong academic
              background and a profound understanding of IT, Sir Umar brings a
              fresh perspective to education that blends technology and
              personalized attention to cater to young learners. Sir Umar Arif's
              dedication to creating a vibrant and inclusive learning atmosphere
              where each child feels valued and empowered reflects his
              commitment to making a significant impact on the educational
              journey of young minds.
            </p>
          </motion.div>
          <motion.div
            className="embla__slide"
            ref={(el) => (slideRef.current[1] = el)}
          >
            <img
              className="w-1/2 rounded-lg mx-auto my-0 h-96 object-top object-cover img-fluid"
              src={image4}
              alt="img-4"
            />
            <p className="mx-10 my-5 text-center card-paragraph">
              <span className="text-3xl card-title font-bold">Sir Kaleem</span>, a dedicated and experienced invigilator at The Aims School. With a Bachelor's degree in Education and 5 years of experience, he ensures that examination procedures are followed meticulously. He is committed to maintaining a fair and conducive environment for students during exams. In addition to his invigilation duties, Sir Kaleem also actively participates in school events and activities to contribute to the overall educational experience of students.
            </p>
          </motion.div>
          <motion.div
            className="embla__slide"
            ref={(el) => (slideRef.current[1] = el)}
          >
            <img
              className="w-1/2 rounded-lg mx-auto my-0 h-96 object-cover object-top img-fluid"
              src={image5}
              alt="img-5"
            />
            <p className="mx-10 my-5 text-center card-paragraph">
              <span className="text-3xl card-title font-bold">Sir Abdulmanan Nazir</span>, a distinguished graduate in Computer Science
              from Lahore Garrison University, stands as an exemplary educator
              in the field of technology and computer science. With a
              commendable teaching experience spanning 3 years, Sir Abdulmanan
              brings a wealth of practical knowledge and academic expertise to
              students. His academic background in Computer Science equips him
              with a deep understanding of the subject matter, enabling him to
              deliver comprehensive and insightful lessons.
            </p>
          </motion.div>
          <motion.div
            className="embla__slide"
            ref={(el) => (slideRef.current[1] = el)}
          >
            <img
              className="w-1/2 rounded-lg mx-auto my-0 h-96 object-cover object-top img-fluid"
              src={image6}
              alt="img-6"
            />
             <p className="mx-10 my-5 text-center card-paragraph">
              <span className="font-bold card-title text-3xl">Madam Sana</span>, an
              esteemed scholar in the realm of Chemistry, holds a Bachelor's
              degree in Chemistry from Government Islamia Graduate Cooper Road
              College, Lahore, and furthered her academic journey with an M.Phil
              in Applied Chemistry from UET. With a rich teaching experience
              spanning 7 years, Madam Sana is a dedicated educator committed to
              fostering a profound understanding and appreciation for the
              sciences, particularly Chemistry.
            </p>
          </motion.div>
          <motion.div
            className="embla__slide"
            ref={(el) => (slideRef.current[1] = el)}
          >
            <img
              className="w-1/2 rounded-lg mx-auto my-0 h-96 object-cover object-top img-fluid"
              src={image7}
              alt="img-7"
            />
             <p className="mx-10 my-5 text-center card-paragraph">
              <span className="font-bold card-title text-3xl">Miss Munazza</span>, a
              dedicated educator in the field of Chemistry, holds a Bachelor's
              degree in Chemistry from Government Islamia Graduate Cooper Road
              College, Lahore, and has furthered her academic pursuits with a
              Bachelor of Education (B.Ed) from Virtual University. With a
              commendable teaching tenure of 7 years, Miss Munazza exemplifies a
              passion for fostering academic excellence and a deep understanding
              of the sciences among her students.
            </p>
          </motion.div>
          <motion.div
            className="embla__slide"
            ref={(el) => (slideRef.current[1] = el)}
          >
            <img
              className="w-1/2 rounded-lg mx-auto my-0 h-96 object-cover object-top img-fluid"
              src={image8}
              alt="img-8"
            />
            <p className="mx-10 my-5 text-center card-paragraph">
              <span className="font-bold card-title text-3xl">Miss Humaira</span>, an
              esteemed educator specializing in Islamic Studies, holds a
              Master's degree in Islamiat from Punjab University. Despite a
              teaching experience of 3 years, Miss Humaira embodies a deep
              commitment to imparting knowledge and fostering a comprehensive
              understanding of Islamic principles.
            </p>
          </motion.div>
          <motion.div
            className="embla__slide"
            ref={(el) => (slideRef.current[1] = el)}
          >
            <img
              className="w-1/2 rounded-lg mx-auto my-0 h-96 object-cover object-top img-fluid"
              src={image9}
              alt="img-9"
            />
             <p className="mx-10 my-5 text-center card-paragraph">
              <span className="font-bold card-title text-3xl">Miss Sonia</span>, an
              industrious professional, holds a Bachelor's degree in Arts from
              Aspire College and has been actively engaged in the field of
              management, contributing her expertise for 2 years. Despite her
              relatively short tenure, Miss Sonia demonstrates a strong
              dedication to the realm of management.
            </p>
          </motion.div>
          <motion.div
            className="embla__slide"
            ref={(el) => (slideRef.current[1] = el)}
          >
            <img
              className="w-1/2 rounded-lg mx-auto my-0 h-96 object-cover object-top img-fluid"
              src={image10}
              alt="img-10"
            />
             <p className="mx-10 my-5 text-center card-paragraph">
              <span className="font-bold card-title text-3xl">Miss Kinza</span>, a graduate
              with a Bachelor's degree in Information Technology from Government
              Dyal Singh Graduate College, embodies a passion for providing
              exceptional care and attention to children in the realm of IT
              education. Her educational background equips her with a strong
              foundation in IT concepts and technologies.
            </p>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default AnimatedCarousel;
