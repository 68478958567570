import React, { useEffect, useRef } from "react";
import { motion, useAnimation } from "framer-motion";

import image from "../media/image4.jpg";

const Contact = () => {
  const cardRef = useRef(null);
  const controls = useAnimation();

  useEffect(() => {
    const cardElement = cardRef.current;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            controls.start({
              opacity: 1,
              rotateY: 0,
              transition: { duration: 1, ease: "easeInOut" },
            });
          }
        });
      },
      { threshold: 0.5 }
    );

    if (cardElement) {
      observer.observe(cardElement);
    }

    return () => {
      if (cardElement) {
        observer.unobserve(cardElement);
      }
    };
  }, [controls]);
  return (
    <div>
      <div className="bg-gray-50 rounded-lg contact-parent p-5 text-start m-5 shadow-lg">
        <motion.div
          className="m-10 flex justify-between items-center contact-container"
          ref={cardRef}
          initial={{ opacity: 0, rotateY: 90 }}
          animate={controls}
        >
          <img
            className="h-[25rem] rounded-lg object-cover"
            src={image}
            alt="contact-img"
          />
          <div className="w-1/2 contact-heading ml-10">
            <h1 className="text-3xl font-bold text-center">Contact us</h1>
            <div className="text-start my-3">
              <div className="flex flex-wrap">
                <p className="my-1 mx-2 font-bold">Email:</p>
                <p className="my-1 mx-2">theaimsschool7866@gmail.com</p>
              </div>
              <div className="flex flex-wrap">
                <p className="my-1 mx-2 font-bold">Whatsapp:</p>
                <p className="my-1 mx-2">+92 308 7693614</p>
              </div>
              <div className="flex flex-wrap">
                <p className="my-1 mx-2 font-bold">Call:</p>
                <p className="my-1 mx-2">+92 324 4739865</p>
              </div>
              <div className="flex flex-wrap">
                <p className="my-1 mx-2 font-bold">Address:</p>
                <p className="my-1 mx-2">
                  Mehmood booti road peer darbar gol chakar, Lahore
                </p>
              </div>
            </div>
          </div>
        </motion.div>
      </div>

      <div className="bg-gray-50 rounded-lg contact-parent p-5 text-center m-5 shadow-lg">
        <iframe
          className="border-0 h-96 w-full"
          title="Our Location"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d382.8100347783552!2d74.39965841571305!3d31.60549905092608!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39191b0050151bd5%3A0xe5895195906277bf!2sThe%20Aims%20School!5e0!3m2!1sen!2s!4v1708603177905!5m2!1sen!2s"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
};

export default Contact;
