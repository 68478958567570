import React from 'react'
import { Link } from 'react-router-dom'

const PageNotFound = () => {
  return (
    <div>
      <h1 className='text-3xl m-5'>Page Not Found!</h1>
      <Link to='/' className='px-4 py-3 bg-blue-600 hover:bg-blue-500 transition-all duration-500 rounded text-white m-5'>Return Home</Link>
    </div>
  )
}

export default PageNotFound
