import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Header from './components/header/Header';
import About from './components/about/AboutIndex';
import PageNotFound from './components/404/404';
import MainIndex from './components/main/MainIndex';
import Contact from './components/contact/Contact';
import Footer from './components/footer/Footer';
import GalleryIndex from './components/gallery/GalleryIndex';

function App() {

  return (
    <Router>
      <div>
        <Header />
        <Routes>
          <Route path="/" element={<MainIndex />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/gallery" element={<GalleryIndex />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
