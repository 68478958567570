import React from 'react'

import HeroSection from '../hero/HeroSection'
import AboutCard from '../about/AboutCard'
import AboutUs from '../about/AboutUs';

const MainIndex = () => {
    const OPTIONS = { dragFree: true };
  const SLIDE_COUNT = 3;
  const SLIDES = Array.from(Array(SLIDE_COUNT).keys());
  return (
    <>
      <HeroSection slides={SLIDES} options={OPTIONS} />
      <AboutCard />
      <AboutUs />
    </>
  )
}

export default MainIndex
