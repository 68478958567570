import React from "react";
import { motion } from "framer-motion";
import { Link, useLocation } from 'react-router-dom'

import logo from '../media/logo.png';

const Header = () => {
  const location = useLocation()
  return (
    <nav className="bg-gray-100 border-gray-200 m-0">
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
          <Link
            to="/"
            className="flex items-center space-x-3 rtl:space-x-reverse"
          >
            <img
              src={logo}
              className="h-20"
              alt="logo"
            />
            <span className="self-center text-2xl font-bold whitespace-nowrap hidden sm:block">
                The Aims School
            </span>
          </Link>
          <button
            data-collapse-toggle="navbar-default"
            type="button"
            className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden focus:outline-none focus:ring-2 focus:ring-gray-200"
            aria-controls="navbar-default"
            aria-expanded="false"
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </button>
          <div className="hidden w-full md:block md:w-auto" id="navbar-default">
            <ul className="font-medium flex flex-col p-4 md:p-0 mt-4 rounded-lg md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0">
              <li>
                <Link
                  to="/"
                  aria-current="page"
                  >
                  <motion.div
                    className={`hover:bg-gray-400 nav-link-item ${location.pathname === '/' ? 'bg-gray-400 text-white' : 'bg-transparent text-black hover:text-white'} flex justify-center items-center rounded h-12 w-fit px-2`}
                    whileHover={{ scale: 1.2 }}
                    whileTap={{ scale: 0.9 }}
                    transition={{ type: "spring", stiffness: 400, damping: 17 }}
                  >
                  Home
                </motion.div>
                </Link>
              </li>
              <li>
                <Link
                  to="/about"
                >
                  <motion.div
                    className={`hover:bg-gray-400 mt-3 md:mt-0 ${location.pathname === '/about' ? 'bg-gray-400 text-white' : 'bg-transparent text-black hover:text-white'} flex justify-center items-center rounded h-12 w-fit px-2`}
                    whileHover={{ scale: 1.2 }}
                    whileTap={{ scale: 0.9 }}
                    transition={{ type: "spring", stiffness: 400, damping: 17 }}
                  >
                  About
                </motion.div>
                </Link>
              </li>
              <li>
                <Link
                  to="/contact"
                >
                  <motion.div
                    className={`hover:bg-gray-400 nav-link-item ${location.pathname === '/contact' ? 'bg-gray-400 text-white' : 'bg-transparent text-black hover:text-white'} flex justify-center items-center rounded h-12 w-fit px-2`}
                    whileHover={{ scale: 1.2 }}
                    whileTap={{ scale: 0.9 }}
                    transition={{ type: "spring", stiffness: 400, damping: 17 }}
                  >
                  Contact Us
                </motion.div>
                </Link>
              </li>
              <li>
                <Link
                  to="/gallery"
                >
                  <motion.div
                    className={`hover:bg-gray-400 nav-link-item ${location.pathname === '/gallery' ? 'bg-gray-400 text-white' : 'bg-transparent text-black hover:text-white'} flex justify-center items-center rounded h-12 w-fit px-2`}
                    whileHover={{ scale: 1.2 }}
                    whileTap={{ scale: 0.9 }}
                    transition={{ type: "spring", stiffness: 400, damping: 17 }}
                  >
                  Gallery
                </motion.div>
                </Link>
              </li>
            </ul>
          </div>
        </div>
    </nav>
  );
};

export default Header;
