import React from 'react'

import img1 from '../media/gallery1.jpeg'
import img2 from '../media/gallery2.jpeg'
import img3 from '../media/gallery3.jpeg'
import img4 from '../media/gallery4.jpeg'
import img5 from '../media/gallery5.jpeg'
import img6 from '../media/gallery6.jpeg'
import img7 from '../media/gallery7.jpeg'
import img9 from '../media/gallery9.jpeg'
import img10 from '../media/gallery11.jpeg'
import img11 from '../media/gallery18.jpeg'
import img12 from '../media/gallery12.jpeg'
import img13 from '../media/gallery13.jpeg'
import img14 from '../media/gallery14.jpeg'
import img16 from '../media/gallery16.jpeg'
import img17 from '../media/gallery17.jpeg'

import './gallery.css'

const GalleryIndex = () => {
  return (
    <div className='bg-[#F3F4F6] m-4 p-6 rounded-xl'>
      <h1 className='text-4xl font-bold text-center my-2'>Explore Our School Events Gallery</h1>
      <div className='flex flex-wrap justify-evenly'>
        <div className='sm:w-[30%] w-full img-container m-1'>
          <img className='rounded-3xl h-[500px] object-cover object-top' src={img1} alt="img" />
        </div>
        <div className='sm:w-[30%] w-full img-container m-1'>
          <img className='rounded-3xl h-[500px] object-cover object-top' src={img2} alt="img" />
        </div>
        <div className='sm:w-[30%] w-full img-container m-1'>
          <img className='rounded-3xl h-[500px] object-cover object-top' src={img3} alt="img" />
        </div>
        <div className='sm:w-[30%] w-full img-container m-1'>
          <img className='rounded-3xl h-[500px] object-cover object-top' src={img4} alt="img" />
        </div>
        <div className='sm:w-[30%] w-full img-container m-1'>
          <img className='rounded-3xl h-[500px] object-cover object-top' src={img5} alt="img" />
        </div>
        <div className='sm:w-[30%] w-full img-container m-1'>
          <img className='rounded-3xl h-[500px] object-cover object-top' src={img6} alt="img" />
        </div>
        <div className='sm:w-[30%] w-full img-container m-1'>
          <img className='rounded-3xl h-[500px] object-cover object-top' src={img7} alt="img" />
        </div>
        <div className='sm:w-[30%] w-full img-container m-1'>
          <img className='rounded-3xl h-[500px] object-cover object-top' src={img9} alt="img" />
        </div>
        <div className='sm:w-[30%] w-full img-container m-1'>
          <img className='rounded-3xl h-[500px] object-cover object-top' src={img10} alt="img" />
        </div>
        <div className='sm:w-[30%] w-full img-container m-1'>
          <img className='rounded-3xl h-[500px] object-cover object-top' src={img11} alt="img" />
        </div>
        <div className='sm:w-[30%] w-full img-container m-1'>
          <img className='rounded-3xl h-[500px] object-cover object-top' src={img12} alt="img" />
        </div>
        <div className='sm:w-[30%] w-full img-container m-1'>
          <img className='rounded-3xl h-[500px] object-cover object-top' src={img13} alt="img" />
        </div>
        <div className='sm:w-[30%] w-full img-container m-1'>
          <img className='rounded-3xl h-[500px] object-cover object-top' src={img14} alt="img" />
        </div>
        <div className='sm:w-[30%] w-full img-container m-1'>
          <img className='rounded-3xl h-[500px] object-cover object-top' src={img16} alt="img" />
        </div>
        <div className='sm:w-[30%] w-full img-container m-1'>
          <img className='rounded-3xl h-[500px] object-cover object-top' src={img17} alt="img" />
        </div>
      </div>
    </div>
  )
}

export default GalleryIndex
