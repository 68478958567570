import React, { useCallback, useEffect, useState } from "react";
import useEmblaCarousel from "embla-carousel-react";
import { flushSync } from "react-dom";

import imageByIndex from "./ImagesIndex";

import "./hero.css";

const TWEEN_FACTOR = 1.2;

const HeroSection = (props) => {
  const { slides, options } = props;
  const [emblaRef, emblaApi] = useEmblaCarousel(options);
  const [tweenValues, setTweenValues] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const onScroll = useCallback(() => {
    if (!emblaApi) return;

    const engine = emblaApi.internalEngine();
    const scrollProgress = emblaApi.scrollProgress();

    const styles = emblaApi.scrollSnapList().map((scrollSnap, index) => {
      let diffToTarget = scrollSnap - scrollProgress;

      if (engine.options.loop) {
        engine.slideLooper.loopPoints.forEach((loopItem) => {
          const target = loopItem.target();
          if (index === loopItem.index && target !== 0) {
            const sign = Math.sign(target);
            if (sign === -1) diffToTarget = scrollSnap - (1 + scrollProgress);
            if (sign === 1) diffToTarget = scrollSnap + (1 - scrollProgress);
          }
        });
      }
      return diffToTarget * (-1 / TWEEN_FACTOR) * 100;
    });
    setTweenValues(styles);
  }, [emblaApi, setTweenValues]);

  const scrollTo = useCallback(
    (index) => {
      if (emblaApi) emblaApi.scrollTo(index);
    },
    [emblaApi]
  );

  useEffect(() => {
    if (!emblaApi) return;

    onScroll();
    emblaApi.on("scroll", () => {
      flushSync(() => onScroll());
    });
    emblaApi.on("reInit", onScroll);
    emblaApi.on("select", () => {
      setCurrentIndex(emblaApi.selectedScrollSnap());
    });
  }, [emblaApi, onScroll]);

  const goToPrevSlide = () => {
    const prevIndex = currentIndex - 1;
    if (prevIndex >= 0) {
      setCurrentIndex(prevIndex);
      scrollTo(prevIndex);
    }
  };

  const goToNextSlide = () => {
    const nextIndex = currentIndex + 1;
    if (nextIndex < slides.length) {
      setCurrentIndex(nextIndex);
      scrollTo(nextIndex);
    }
  };

  return (
    <div className="embla">
      <div className="embla__viewport" ref={emblaRef}>
        <div className="embla__container">
          {slides &&
            slides.map((index) => (
              <div className="embla__slide" key={index}>
                <div className="embla__slide__number">
                  <span>{index + 1}</span>
                </div>
                <div className="embla__parallax">
                  <div className="controls absolute top-[40%] left-10 z-50">
                    <button
                      onClick={goToPrevSlide}
                      disabled={currentIndex === 0}
                      className="p-2 rounded bg-white/70 transition-all"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"/>
                      </svg>
                    </button>
                  </div>
                  <div className="controls absolute top-[40%] z-50 right-10">
                    <button
                      className="right-0 transition-all bg-white/70 p-2 rounded"
                      onClick={goToNextSlide}
                      disabled={currentIndex === slides.length - 1}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
                      </svg>
                    </button>
                  </div>
                  <div className="absolute z-40 top-2/4 ml-12 w-fit">
                    <h1
                      className={`sm:text-3xl md:text-5xl font-bold max-w-3xl ${
                        index > 0 ? "text-white carousel-heading" : "text-black"
                      }`}
                    >
                      Welcome to The Aims School Education System
                    </h1>
                    <p
                      className={`sm:text-lg md:text-xl font-semibold ${
                        index > 0 ? "text-white carousel-heading" : "text-black"
                      }`}
                    >
                      Excellence in education, aimed for success
                    </p>
                  </div>
                  <div
                    className="embla__parallax__layer relative"
                    style={{
                      ...(tweenValues.length && {
                        transform: `translateX(${tweenValues[index]}%)`,
                      }),
                    }}
                  >
                    <img
                      className="embla__slide__img embla__parallax__img"
                      src={imageByIndex(index)}
                      alt="hero-img"
                    />
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
