import React, { useEffect, useRef } from "react";
import { motion, useAnimation } from "framer-motion";

import image1 from "../media/students.png";
import image2 from "../media/students2.jpg";
import image3 from "../media/accountImage.jpg";

import './about.css';

const About = () => {
  const cardRef = useRef(null);
  const controls = useAnimation();

  useEffect(() => {
    const cardElement = cardRef.current;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            controls.start({
              opacity: 1,
              rotateY: 0,
              transition: { duration: 1, ease: "easeInOut" },
            });
          }
        });
      },
      { threshold: 0.5 }
    );

    if (cardElement) {
      observer.observe(cardElement);
    }

    return () => {
      if (cardElement) {
        observer.unobserve(cardElement);
      }
    };
  }, [controls]);

  return (
    <div className="m-0">
      <h1 className="text-4xl font-bold ml-8 mt-6 mb-0 text-center">About us</h1>
      <div className="sm:block md:flex justify-between items-center px-5">
        <div>
          <motion.div
            initial={{ x: "-100vw", opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 1 }}
          >
            <p className="text-2xl font-normal mb-3 mx-3 text-center sm:text-start">
              The Aims School isn't just an educational institution, it's a
              transformative journey. With a rich legacy spanning decades, The
              Aims School has excelled across various educational domains,
              offering a comprehensive network of educational services. From
              nurturing young minds in Schools, to providing comprehensive
              Evening Coaching, Entry Test Preparation, and Competitive Exam
              Preparatio. The Aims School stands as a beacon of educational
              prowess. Complemented by a distinguished cadre of accomplished
              students and professionals, our institution takes pride in its
              commitment to excellence. Our exceptional faculty is the
              cornerstone of The Aims School's success.
            </p>
          </motion.div>
        </div>
        <motion.img
          className="sm:w-full md:w-1/2 rounded-lg"
          src={image1}
          alt="img-1"
          initial={{ x: "100vw", opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 1 }}
        />
      </div>
      <div className="sm:block md:flex justify-between items-center px-5">
        <motion.img
          className="sm:w-full md:w-1/2 rounded-lg"
          src={image2}
          alt="img-2"
          initial={{ x: "-100vw", opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 1 }}
        />
        <motion.div
          initial={{ x: "100vw", opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <p className="text-2xl font-normal m-3 text-center sm:text-start">
            At The Aims School, our success is founded upon an exceptionally
            proficient faculty, purposefully designed campus equipped with
            cutting-edge facilities, and a meticulous quality assurance system.
            These essential elements form the bedrock of our institution,
            ensuring an environment conducive to academic excellence and
            holistic student development. We uphold an unwavering dedication to
            quality assurance, ensuring an enriching and empowering educational
            experience for every student who walks through our doors.
          </p>
        </motion.div>
      </div>

      <div className="bg-gray-100 px-3 py-5 rounded-lg mx-5 my-3 shadow-lg">
        <motion.div
          className="sm:my-3 md:m-10"
          ref={cardRef}
          initial={{ opacity: 0, rotateY: 90 }}
          animate={controls}
        >
          <h1 className="text-3xl font-bold my-2">Vision:</h1>
          <p className="sm:text-lg text-sm">
            To provide a nurturing academic environment where students are
            transformed into creative, confident and socially responsible
            students who are ready to take on life challenges.
          </p>
          <h1 className="text-3xl font-bold my-2">Mission:</h1>
          <p className="sm:text-lg text-sm">
            The Aims School is committed to delivering a comprehensive and
            inclusive education, grounded in our core values. Our focus is on
            cultivating an environment that fosters innovation, life skills, and
            problem-solving abilities. With a dedication to providing quality
            education, we strive to create a high-performance setting that
            empowers students to excel not only academically but also equips
            them with the essential skills needed for success in life.
          </p>
        </motion.div>
      </div>

      <div className="bg-gray-100 rounded-lg p-5 text-center m-5 shadow-lg block sm:flex sm:items-center">
        <div className="w-full sm:w-1/2">
          <img className="rounded" src={image3} alt="img" />
        </div>
        <div className="w-full sm:w-1/2">
          <h1 className="text-3xl font-bold">Our Social Accouts</h1>
          <div className="flex items-center justify-center">
          <h1 className="text-3xl font-bold mr-3">Sir Tayyab</h1>
          <div className="mx-2">
            <a
              href="https://www.instagram.com/tayyab.one"
              rel="noreferrer"
              target="_blank"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-instagram account-icons"
                viewBox="0 0 16 16"
              >
                <title>Instagram</title>
                <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334" />
              </svg>
            </a>
          </div>
          <div>
            <a
              href="https://www.facebook.com/rangbaz222"
              rel="noreferrer"
              target="_blank"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-facebook account-icons"
                viewBox="0 0 16 16"
              >
                <title>Facebook</title>
                <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951" />
              </svg>
            </a>
          </div>
          </div>
          <div className="flex items-center justify-center">
          <h1 className="text-3xl font-bold mr-3">Sir Salaar</h1>
          <div className="mx-2">
            <a
              href="https://www.instagram.com/btw_salaar_/"
              rel="noreferrer"
              target="_blank"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-instagram account-icons"
                viewBox="0 0 16 16"
              >
                <title>Instagram</title>
                <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334" />
              </svg>
            </a>
          </div>
          <div>
            <a
              href="https://www.facebook.com/salaar.ahmad.16547"
              rel="noreferrer"
              target="_blank"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-facebook account-icons"
                viewBox="0 0 16 16"
              >
                <title>Facebook</title>
                <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951" />
              </svg>
            </a>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
